<template>
  <div class="payError">
    <!-- 头部 -->
    <van-nav-bar fixed placeholder z-index="100" title="支付失败"   left-arrow @click-left="onClickLeft" />

    <!-- 内容 -->
    <div class="content">
        <div class="cbox">
          <div class="image">
             <img src="../assets/image/payError.png" alt="">
             <p>支付失败</p>
          </div>
          <div class="text">
            <p>支付失败了,请重新再试一次吧</p>
          </div>
          <div class="again" @click="Repayments(paydata)">重新支付</div>
          <div class="btn" @click="onClickLeft">返回</div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        paydata:{},
        oid:'',
        userId:'',
        orderType:''
      }
    },
    methods:{
        onClickLeft(){
           this.$router.push('/home/InformationBase')
        },
        Repayments(paydata){
          window.location.href = `https://p.writemall.com/order2/wxH5WebProductOrderCallBack?userid=${paydata.userId}&oid=${paydata.oid}&orderType=${paydata.orderType}&platform=1`
        }
    },
    created(){
       this.oid = this.$route.query.oid
       this.userId = this.$route.query.userId
       this.orderType = this.$route.query.orderType
       this.paydata = {
         oid:this.oid,
         userId:this.userId,
         orderType:this.orderType,
       }
    }
  }
</script>

<style lang="scss" scoped>
.payError{
   font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
  .content{
    margin-top: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    .cbox{
      text-align: center;
       .image{
         display: flex;
         justify-content: center;
         flex-direction: column;
         align-items: center;
          img{
            width: 120px;
            height: 120px;
            margin-left: 20px;
          }
          p{
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
            margin-top: 20px;
          }
       }
       .text{
         font-size: 14px;
         font-family: PingFang SC;
         font-weight: 500;
         color: #686868;
         margin-top: 15px;
       }
       .again{
          margin-top: 105px;
          border: 1px solid transparent;
          background: #4588ff;
          width: 258px;
          height: 57px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FEFEFE;
          text-align: center;
          line-height: 57px;
          border-radius: 27px;
       }
       .btn{
          margin-top: 20px;
          border: 1px solid #666666;
          width: 258px;
          height: 57px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          text-align: center;
          line-height: 57px;
          color: #999999;
          border-radius: 27px;
       }
    }
  }  
}
</style>